import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Contentpage from "../components/templates/Contentpage"
import Seo from '../components/atoms/Seo'
import Row from '../components/molecules/Row'
import Column from '../components/atoms/Column'
import Box from "../components/atoms/Box"
import Divider from "../components/atoms/Divider"
import Title from '../components/atoms/Title'

const StyledList = styled.ul`
    list-style: none;
    font-size: 15px;
    font-weight: 200;
    text-align: center;
    margin: 0;
    padding: 0;
`;
const StyledListItem = styled.li`
    display: inline-block;
    padding: 0 10px;
    border-right: 1px solid #84827c;
    margin-bottom: 15px;
`;

// markup
const AboutPage = () => {
    return (
        <Contentpage>
            <Seo
            title='About us'
            description='About Pedley Law PLLC is a law firm providing legal, business, and strategic counsel primarily to start-up and emerging growth companies and individuals.'
            />
            <Row>
                <Column size='50%'>
                    <StaticImage src='../images/about/city.jpeg' alt='Pedley Law City' />
                </Column>
                <Column size='50%'>
                    <Box color='darkbeige'>
                        <Divider variant='line'>
                            <p>
                                Pedley Law PLLC is a law firm providing legal, business,
                                and strategic counsel primarily to start-up and
                                emerging growth companies and individuals.
                                David Pedley been involved in over $1 billion
                                in financing and M&A transactions,
                                including more than $500 million in venture capital financings.
                            </p>
                        </Divider>
                    </Box>
                </Column>
            </Row>
            
            <Row>
                <Column size='100%'>
                    <Box color='beige' style={{marginTop: '32px'}}>
                        <Row>
                            <Column>
                                <div style={{margin: '40% 0'}}>
                                    <StaticImage src='../images/about/business-planning.png' alt='Pedley Law, Business Planning & Strategic Counseling' width={300} />
                                </div>
                            </Column>
                            <Column>
                                <ul>
                                    <li>General Corporate: Formations, Governance, Compliance and Contracts</li>
                                    <li>Strategic Partnerships and Joint Ventures</li>
                                    <li>Equity and Debt Financing: Angel, Venture Capital and Private Equity</li>
                                    <li>Securities</li>
                                    <li>Fund Formation</li>
                                    <li>International Business Transactions</li>
                                    <li>Employment</li>
                                    <li>Licensing</li>
                                    <li>Mergers & Acquisitions</li>
                                    <li>Real Estate</li>
                                </ul>
                            </Column>
                        </Row>
                    </Box>
                </Column>
            </Row>

            <Divider />

            <Row>
                <Column size='60%'>
                    <Box color='darkbeige'>
                        <Title level={3} color='#de5827'>INDUSTRIES</Title>
                        <StyledList>
                            <StyledListItem>Information Technology</StyledListItem>
                            <StyledListItem>Internet & Digital Media</StyledListItem>
                            <StyledListItem>Commercial Real Estate</StyledListItem>
                            <StyledListItem>Health Care</StyledListItem>
                            <StyledListItem>Biotech</StyledListItem>
                            <StyledListItem>Education</StyledListItem>
                            <StyledListItem>Retail and Distribution</StyledListItem>
                            <StyledListItem>Advanced Energy Technology</StyledListItem>
                            <StyledListItem>Import/Export</StyledListItem>
                        </StyledList>
                    </Box>
                </Column>
                <Column size='40%'>
                    <Box color='orange'>
                    WHEN NECESSARY WE WORK WITH BOTH YOU AND OTHER FIRMS WITH WHICH WE COLLABORATE TO MANAGE THE DELIVERY OF LEGAL SERVICES ON YOUR BEHALF, ENSURING COST-EFFECTIVE AND SEAMLESS PROVISION OF ALL OF YOUR LEGAL NEEDS.
                    </Box>
                </Column>
            </Row>
        </Contentpage>
    )
}

export default AboutPage
