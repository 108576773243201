import React from "react"
import styled from 'styled-components';

const StyledEmptyDivider = styled.div`
    width: 100%;
    margin-top: 32px;

    @media only screen and (max-width: 600px) {
        width: 280px;
        margin-top: 32px;
    }
`;

const StyledLineDivider = styled.div`
    width: 100%;
    margin: 64px auto;
    padding: 64px 0;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    text-align: center;

    @media only screen and (max-width: 600px) {
        width: 280px;
    }
`;

const Divider = ({ variant, children, ...props }) => {
  let BoxComponent = null;

    switch (variant) {
        case 'line':
            BoxComponent = ( children ) => <StyledLineDivider {...props}>{children}</StyledLineDivider>;
            break;
        default:
            BoxComponent = ( children ) =>   <StyledEmptyDivider {...props}>{children}</StyledEmptyDivider>;
            break;
    }

    return BoxComponent(children)
}

export default Divider