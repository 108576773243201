import React from "react"
import PropTypes from "prop-types"

import Container from "../molecules/Container"
import Header from "../organisms/Header"
import Footer from "../organisms/Footer"

const Contentpage = ({children}) => {
    return (
        <>
            <Header />
            <Container>
                <main>
                    {children}
                </main>
            </Container>
            <Footer />
        </>
    )
}

Contentpage.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Contentpage