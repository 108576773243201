import React from "react"
import styled from "styled-components"

import Container from '../../molecules/Container'

const StyledFooter = styled.footer`
    padding: 1rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
`;

const StyledParagraph = styled.p`
    color: #312f37;
    border-left: 1px solid white;
    display: block;
    font-size: 12px;
    line-height: 1.5;
`;
const StyledAddress = styled.address`
    color: #312f37;
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    padding: 0;
    margin: 0;

    a {
        color: #312f37;
        font-size: 12px;
    }
`;

const Footer = () => {
    return (
        <StyledFooter className="clearfix">
            <Container>
                <StyledParagraph>
                    © {new Date().getFullYear()} - PEDLEY LAW PLLC<br />
                </StyledParagraph>
                <StyledAddress>
                    Mail: <a href="mailto:dpedley@pedleylaw.com">dpedley@pedleylaw.com</a> <br />
                    Phone: 720-445-7744<br />
                    Address: 4610 S. Ulster St. Suite 150 Denver, CO 80237
                </StyledAddress>
            </Container>
        </StyledFooter>
    )
}

export default Footer