import React from 'react'
import styled from "styled-components"

const StyledContainer = styled.section` 
  background: ${props => props.background || 'transparent'};
  padding: 0 16px;
  
  @media (min-width: 1024px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
`;

const StyledContainerInner = styled.div`
  justify-content: space-around;
  display: ${props => props.display || 'block'};
  align-items: center;
  margin: ${props => props.margin || '0 auto'};
  flex-direction: column;
  padding: 32px 0;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Container = ({ background, margin, padding, display, children }) => {
  return (
    <StyledContainer background={background}>
      <StyledContainerInner display={display} margin={margin} padding={padding}>
        {children}
      </StyledContainerInner>
    </StyledContainer>
  )
}

export default Container