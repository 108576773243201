import React from "react";
import styled from "styled-components"

const StyledContainer = styled.div`
    width: ${props => props.size};
    margin: 0 16px;
    
    @media only screen and (max-width: 800px) {
        order: ${props => props.gridOrder};
        width: 100%;
        margin: 0;
    }

    img {
        margin: 0;
    }
`;

const Column = ({children, size, gridOrder, ...props}) => {
    return (
        <StyledContainer size={size} gridOrder={gridOrder} {...props}>
            {children}
        </StyledContainer>
    )
}

export default Column