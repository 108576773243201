import React from "react";
import styled from "styled-components"

import Pattern from './pattern.png'

const TransparentBox = styled.div`
    color: #404040;
    padding: 2.5rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;

const DarkBeigeBox = styled.div`
    background: #d4d1c5;
    color: #404040;
    padding: 2.5rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const BeigeBox = styled.div`
    background: #f5f4ec;
    color: #404040;
    padding: 2.5rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const OrangeBox = styled.div`
    background: #de5826;
    color: #fff;
    padding: 2.5rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;
const OrangePatternBox = styled.div`
    background: rgb(222,88,39) url(${Pattern}) center center / cover;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.6;
    padding: 4rem;
    width: 100%;

    @media only screen and (max-width: 1000px) {
        padding: 1.5rem;
    }
`;

const Box = ({children, color, fullWidth, ...props}) => {
    const extendedClass = fullWidth ? 'FullWidth' : ''
    let BoxComponent = null;

    switch (color) {
        case 'darkbeige':
            BoxComponent = ( children ) => <DarkBeigeBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></DarkBeigeBox>;
            break;
        case 'beige':
            BoxComponent = ( children ) => <BeigeBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></BeigeBox>;
            break;
        case 'orange':
            BoxComponent = ( children ) => <OrangeBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></OrangeBox>;
            break;
        case 'orangepattern':
            BoxComponent = ( children ) => <OrangePatternBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></OrangePatternBox>;
            break;
        default:
            BoxComponent = ( children ) => <TransparentBox className={'Box clearfix ' + extendedClass} {...props}><div>{children}</div></TransparentBox>;
            break;
    }

    return BoxComponent(children)
}

export default Box
