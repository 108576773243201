import React from "react";
import styled, {css} from "styled-components"

const SharedContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const DesktopContainer = styled.div`
    ${SharedContainer}
    flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};
`

const MobileContainer = styled.div`
    ${SharedContainer}
    flex-wrap: ${props => props.wrap ? 'wrap' : 'nowrap'};

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const Row = ({children, allwaysDesktop, wrap, ...props}) => {
    return (
        allwaysDesktop ? 
            <DesktopContainer wrap={wrap} {...props}>
                {children}
            </DesktopContainer> :
            <MobileContainer wrap={wrap} {...props}>
                {children}
            </MobileContainer>

    )
}

export default Row